import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/SEO/index"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Button from "../components/Button"

function PageNotFound(props) {

  const { settings } = useStaticQuery(graphql`
        query SettingsNotFound {
          settings: allStoryblokEntry(filter: {field_component: {eq: "settings"}}) {
            edges {
              node {
                name
                full_slug
                content
              }
            }
          }
        } 
      `)

      let hasSetting = settings.edges[0].node
      let content = typeof hasSetting.content === "string" ? JSON.parse(hasSetting.content) : hasSetting.content
      let parsedSetting = Object.assign({}, content, {content: content})

      return (
        <div>
          <SEO title="Seite nicht gefunden" lang="de" />
          <Header settings={parsedSetting} location={{pathname: ""}} />
          <main>
            <div class="top-spacing">
              <div class="container">
                <div class="text-block">
                  <h1>Seite konnte nicht gefunden werden</h1>
                  <p>Die gewünschte Seite konnte nicht gefunden werden. Bitte versuchen Sie es noch einmal oder kehren Sie zur Startseite zurück.</p>
                  <Button to="/" label="Startseite öffnen" type="primary" />
                </div>
              </div>
            </div>
          </main>
          <Footer settings={parsedSetting} />
        </div>
      )
}

export default PageNotFound